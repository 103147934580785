import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { Link as RouterLink, generatePath, useLocation } from "react-router-dom";
import { Button, ButtonVariant, Link } from "@lookiero/react-ui-kit";
import { ReactIntl } from "@lookiero/i18n.js";
import { ROOT_I18N_PREFIX, RootI18n } from "@src/ui/i18n/I18nRootMessages";
import { useCatalogSelection } from "@src/ui/views/_behaviors/useCatalogSelection";
import useIsRoute from "../../_behaviors/useIsRoute";
import Routes from "../../../_routing/routes";
import ProductSelectionSummary from "../ProductSelectionSummary/ProductSelectionSummary";

import { useGetCurrentEmployee } from "@src/ui/views/_behaviors/employeeProvider";
import PrintLabelsModal from "./PrintLabelsModal/PrintLabelsModal";

import "./header.css";

interface HeaderMenuItemProps {
  readonly to: string;
  readonly children: React.ReactNode;
}
const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ to, children }: HeaderMenuItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const isFashionLabsRouter = location.pathname.includes("fl-models");

  return (
    <Link
      className={classNames("header__menu-item", {
        "header__menu-item--active": isActive,
        "header__menu-item--fl-active": isFashionLabsRouter,
      })}
      to={to}
    >
      {children}
    </Link>
  );
};

type HeaderProps = {
  readonly locale: string;
  readonly onPrintProductVariantLabels: (productVariant: string, quantity: string, onSuccess: () => void) => void;
};

const Header: React.FC<HeaderProps> = ({ locale, onPrintProductVariantLabels }) => {
  const [showPrintLabelsModal, setShowPrintLabelsModal] = useState(false);

  const location = useLocation();
  const productsPath = generatePath(`/${Routes.PRODUCTS}`, { locale });
  const flProductsOverviewPath = generatePath(`/${Routes.FL_PRODUCTS_OVERVIEW}`, { locale });
  const familiesPath = generatePath(`/${Routes.FAMILIES_OVERVIEW}`, { locale });
  const priceManagementPath = generatePath(`/${Routes.PRICE_MANAGEMENT}`, { locale });

  const isProductsRoute = useIsRoute(productsPath);
  const isLoginRoute = useIsRoute(generatePath(Routes.LOGIN, { locale }));
  const isProductRoute = useMemo(() => location.pathname.includes("/product/"), [location]);

  const employee = useGetCurrentEmployee();
  const hasCatalogCategoriesRole = employee?.hasCatalogCategoriesRole() as boolean;
  const hasPriceManagementRole = employee?.hasPriceManagementRole() as boolean;
  const hasPrintGarmentLabelsRole = employee?.hasPrintGarmentLabelsRole() as boolean;

  const isLoginOrProductRoute = isLoginRoute || isProductRoute;

  const { isSelection } = useCatalogSelection();
  return !isLoginOrProductRoute ? (
    <header className="header">
      <nav className="header__menu">
        <HeaderMenuItem to={productsPath}>
          <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_CATALOG} prefix={ROOT_I18N_PREFIX} />
        </HeaderMenuItem>
        <HeaderMenuItem to={flProductsOverviewPath}>
          <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_FASHION_LABS} prefix={ROOT_I18N_PREFIX} />
        </HeaderMenuItem>
        {hasCatalogCategoriesRole ? (
          <HeaderMenuItem to={familiesPath}>
            <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_CATEGORIES} prefix={ROOT_I18N_PREFIX} />
          </HeaderMenuItem>
        ) : null}
        {hasPriceManagementRole ? (
          <HeaderMenuItem to={priceManagementPath}>
            <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_PRICE_MANAGEMENT} prefix={ROOT_I18N_PREFIX} />
          </HeaderMenuItem>
        ) : null}
      </nav>
      {isProductsRoute ? (
        <nav className="header__menu">
          <ProductSelectionSummary />
          {hasPrintGarmentLabelsRole ? (
            <div className="header__menu-item">
              <Button variant={ButtonVariant.PRIMARY} onClick={() => setShowPrintLabelsModal(true)}>
                <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_PRINT_LABELS} prefix={ROOT_I18N_PREFIX} />
              </Button>
            </div>
          ) : null}
          <RouterLink className="header__menu-item" to={`${productsPath}/${Routes.AVAILABILITY}${location.search}`}>
            <Button disabled={isSelection} variant={ButtonVariant.PRIMARY}>
              <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_AVAILABILITY} prefix={ROOT_I18N_PREFIX} />
            </Button>
          </RouterLink>
          <RouterLink className="header__menu-item" to={`${productsPath}/${Routes.VISIBILITY_LITE}${location.search}`}>
            <Button disabled={isSelection} variant={ButtonVariant.PRIMARY}>
              <ReactIntl.I18nMessage id={RootI18n.HEADER_MENU_VISIBILITY} prefix={ROOT_I18N_PREFIX} />
            </Button>
          </RouterLink>
        </nav>
      ) : null}
      {showPrintLabelsModal ? (
        <PrintLabelsModal
          onPrint={(productVariant, quantity) =>
            onPrintProductVariantLabels(productVariant, quantity, () => setShowPrintLabelsModal(false))
          }
          onClose={() => setShowPrintLabelsModal(false)}
          isVisible
        />
      ) : null}
    </header>
  ) : null;
};

export default Header;
