import { DomainEvent } from "@lookiero/messaging.js";
import { Orders } from "./OrdersModel";

class OrdersCreated extends DomainEvent {
  public orders: Orders;

  public constructor(orders: Orders) {
    super();
    this.orders = orders;
  }

  protected messageName(): string {
    return "OrdersCreated";
  }
}

export default OrdersCreated;
