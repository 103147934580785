import { DomainEvent, ProcessManager } from "@lookiero/messaging.js";
import { handlerContainer } from "../helpers/handlerContainer";
import { Container } from "inversify";
import { registers } from "../helpers/registers";
import Class from "@lookiero/messaging.js/dist/Class";

import AvailabilityEdited from "@src/core/domain/availability/model/AvailabilityEdited";
import EmployeeAuthenticated from "@src/core/domain/employee/model/EmployeeAuthenticated";
import ErrorThrown from "@src/core/domain/error/model/ErrorThrown";
import FamilyCreated from "@src/core/domain/family/model/FamilyCreated";
import FamilyFeatureValuesCloned from "@src/core/domain/family/model/FamilyFeatureValuesCloned";
import FamilyFeatureValuesEdited from "@src/core/domain/family/model/FamilyFeatureValuesEdited";
import FashionLabsModelCreated from "@src/core/domain/fashionLabsModel/model/FashionLabsModelCreated";
import FashionLabsModelEdited from "@src/core/domain/fashionLabsModel/model/FashionLabsModelEdited";
import FashionLabsProductCreated from "@src/core/domain/fashionLabsProduct/model/FashionLabsProductCreated";
import FashionLabsProductEdited from "@src/core/domain/fashionLabsProduct/model/FashionLabsProductEdited";
import OrderCreated from "@src/core/domain/order/model/OrderCreated";
import OrderItemAdded from "@src/core/domain/order/model/OrderItemAdded";
import OrderItemRemoved from "@src/core/domain/order/model/OrderItemRemoved";
import OrderPlaced from "@src/core/domain/order/model/OrderPlaced";
import { processManagerRegisterList } from "./processManagerRegisterList";
import LabelPrinted from "../../domain/label/model/LabelPrinted";

interface ProcessManagerDependeciesFunctionArgs {
  readonly container: Container;
}
type ProcessManagerDependeciesFunctionResult = [Class<DomainEvent>, ProcessManager<DomainEvent>][];

interface ProcessManagerDependeciesFunction {
  (args: ProcessManagerDependeciesFunctionArgs): Promise<ProcessManagerDependeciesFunctionResult>;
}

const processManagerDependecies: ProcessManagerDependeciesFunction = async ({ container }) => {
  const { listRegister } = registers({ container });
  const handler = handlerContainer({ container });

  listRegister(processManagerRegisterList);

  return Promise.resolve([
    [ErrorThrown, handler("LogWhenError")],
    [ErrorThrown, handler("CreateNotificationWhenError")],
    [ErrorThrown, handler("LogoutWhenUnAuthorizedEmployeeError")],
    [AvailabilityEdited, handler("CreateNotificationWhenAvailabilityEdited")],
    [FashionLabsProductCreated, handler("CreateNotificationWhenFashionLabsProductCreated")],
    [FashionLabsProductEdited, handler("CreateNotificationWhenFashionLabsProductEdited")],
    [FashionLabsModelCreated, handler("CreateNotificationWhenFashionLabsModelCreated")],
    [FashionLabsModelEdited, handler("CreateNotificationWhenFashionLabsModelEdited")],
    [EmployeeAuthenticated, handler("CreateEmployeeWhenAuthenticated")],
    [OrderItemRemoved, handler("CreateNotificationWhenOrderItemRemoved")],
    [OrderItemAdded, handler("CreateNotificationWhenOrderItemAdded")],
    [OrderPlaced, handler("CreateNotificationWhenOrderPlaced")],
    [OrderCreated, handler("CreateNotificationWhenOrderCreated")],
    [FamilyCreated, handler("CreateNotificationWhenFamilyCreated")],
    [FamilyFeatureValuesCloned, handler("CreateNotificationWhenFamilyFeatureValuesCloned")],
    [FamilyFeatureValuesEdited, handler("CreateNotificationWhenFamilyFeatureValuesEdited")],
    [LabelPrinted, handler("CreateNotificationWhenLabelPrinted")],
  ] as ProcessManagerDependeciesFunctionResult);
};

export { processManagerDependecies };
