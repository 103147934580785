import { Schema, object, string } from "yup";

interface SchemaInterface {
  quantity: string;
  productVariant: string;
}

const validationSchema: Schema<SchemaInterface> = object().shape({
  quantity: string().required("Debes especificar una cantidad"),
  productVariant: string().required("Debes introducir un product variant"),
});

export default validationSchema;
