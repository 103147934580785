import { CommandHandler } from "@lookiero/messaging.js";
import PrintProductVariantLabels from "./PrintProductVariantLabels";
import Label from "../../model/Label";
import LabelRepository from "../../model/LabelRepository";

class PrintProductVariantLabelsHandler implements CommandHandler<PrintProductVariantLabels> {
  private repository: LabelRepository;

  public constructor(repository: LabelRepository) {
    this.repository = repository;
  }
  public execute({ reference, quantity, printerLine }: PrintProductVariantLabels): Promise<void> {
    const label = Label.printByReference(reference, quantity, printerLine);
    return this.repository.printByReference(label);
  }
}

export default PrintProductVariantLabelsHandler;
