import { Command } from "@lookiero/messaging.js";
import { Orders } from "../../model/OrdersModel";

class CreateMultipleOrdersModel extends Command {
  public readonly orders: Orders;

  public constructor(orders: Orders) {
    super();
    this.orders = orders;
  }

  public messageName(): string {
    return "CreateMultipleOrders";
  }
}

export default CreateMultipleOrdersModel;
