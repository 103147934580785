import { Command } from "@lookiero/messaging.js";

class CreateFamily extends Command {
  public familyId: string;
  public category: string;
  public letter: string;
  public familyName: string;

  public constructor({
    familyId,
    category,
    letter,
    familyName,
  }: {
    familyId: string;
    category: string;
    letter: string;
    familyName: string;
  }) {
    super();
    this.familyId = familyId;
    this.category = category;
    this.letter = letter;
    this.familyName = familyName;
  }

  public messageName(): string {
    return "CreateFamily";
  }
}

export default CreateFamily;
