import FashionLabsProduct from "@src/core/domain/fashionLabsProduct/model/FashionLabsProduct";
import { FashionLabsProductResponse } from "./FashionLabsProductResponse";

const toFashionLabsProduct = (dto: FashionLabsProductResponse): FashionLabsProduct => {
  const features = dto.features?.map(({ id, values }) => ({
    id,
    values: values.map(({ id, qualifier }) => {
      const purgedQualifier = qualifier ? { id: qualifier.id, values: qualifier.values.map(({ id }) => ({ id })) } : {};
      return { id, ...purgedQualifier };
    }),
  }));

  const productModel = {
    modelId: dto.product_model?.model_id,
    sizeId: dto.product_model?.size_id,
    modelFitting: dto.product_model?.model_fitting,
  };

  return new FashionLabsProduct(
    dto.id,
    dto.title,
    dto.note,
    dto.family.id,
    dto.brand.id,
    dto.group,
    dto.channels,
    features,
    productModel,
    dto.segment,
    dto.media,
  );
};

export { toFashionLabsProduct };
