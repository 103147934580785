import React from "react";
import PriceManagementOutcomeTemplate from "../../component-library/templates/PriceManagementOutcome/PriceManagementOutcome";
import { useLocation } from "react-router-dom";
import useExportCSV from "../_behaviors/useExportCSV";

const PriceManagementOutcome: React.FC = () => {
  // TODO: Provisional, to be removed when the API is integrated
  const {
    state: { data },
  } = useLocation();
  const exportFile = useExportCSV();

  return <PriceManagementOutcomeTemplate productsWithErrors={data} exportFile={exportFile} />;
};

export default PriceManagementOutcome;
