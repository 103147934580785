import { useDispatch } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import { FileItem } from "./useImportCSV";

const LIMIT_ROWS = 100;

interface ValidateCSV {
  validateIsEmpty: (data: FileItem[]) => boolean;
  validateMaxRows: (data: FileItem[], maxRows?: number) => boolean;
  validateRequiredColumns: (data: FileItem[], requiredColumns: string[]) => boolean;
  validateDuplicatedRows: (data: FileItem[]) => boolean;
  validateNotEmptyValues: (data: FileItem[]) => boolean;
  validateCustomMethod: (data: FileItem[], customMethod: (data: FileItem) => boolean, errorMessage?: string) => boolean;
}

const useValidateCSV = (): ValidateCSV => {
  const dispatch = useDispatch();

  const validateIsEmpty = (data: FileItem[]): boolean => {
    if (!data || data.length === 0) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: "No data to validate",
        }),
      );
      return false;
    }
    return true;
  };

  const validateMaxRows = (data: FileItem[], maxRows?: number): boolean => {
    if (data.length > (maxRows || LIMIT_ROWS)) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: "Too many rows to validate",
        }),
      );
      return false;
    }
    return true;
  };

  const validateRequiredColumns = (data: FileItem[], requiredColumns: string[]): boolean => {
    const firstRow = data[0];
    const columns = Object.keys(firstRow);
    const hasRequiredColumns = requiredColumns.every((column) => columns.includes(column));

    if (!hasRequiredColumns) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: "Missing required columns",
        }),
      );
      return false;
    }
    return true;
  };

  const validateDuplicatedRows = (data: FileItem[]): boolean => {
    const uniqueRows = new Set(data.map((row) => JSON.stringify(row)));

    if (uniqueRows.size !== data.length) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: "Duplicated rows",
        }),
      );
      return false;
    }
    return true;
  };

  const validateNotEmptyValues = (data: FileItem[]): boolean => {
    const values = data.map((row) => Object.values(row));
    const hasEmptyValues = values.some((row) => row.some((value) => !value));

    if (hasEmptyValues) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: "Empty values",
        }),
      );
      return false;
    }
    return true;
  };

  const validateCustomMethod = (
    data: FileItem[],
    customMethod: (data: FileItem) => boolean,
    errorMessage?: string,
  ): boolean => {
    const isValid = data.every((row) => {
      return customMethod(row);
    });
    if (!isValid) {
      dispatch(
        new CreateNotification({
          level: NotificationLevel.ERROR,
          content: errorMessage || "Custom method failed",
        }),
      );
      return false;
    }
    return true;
  };

  return {
    validateIsEmpty,
    validateMaxRows,
    validateRequiredColumns,
    validateDuplicatedRows,
    validateNotEmptyValues,
    validateCustomMethod,
  };
};

export default useValidateCSV;
