const PRINT_LABELS_I18N_PREFIX = "catalog.app.print_labels.";

const PrintLabelsI18n = {
  TITLE: "title",
  TEXT: "text",
  FIELD_QUANTITY: "field_quantity",
  FIELD_REFERENCE: "field_reference",
  BUTTON_CANCEL: "button_cancel",
  BUTTON_PRINT: "button_print",
};

export { PRINT_LABELS_I18N_PREFIX, PrintLabelsI18n };
