import { useCallback, useState } from "react";
import { useDispatch } from "@lookiero/messaging.js";
import { AsyncActionState } from "@lookiero/react-ui-kit";
import PrintProductVariantLabels from "@src/core/domain/label/command/printProductVariantLabels/PrintProductVariantLabels";
import CreateNotification from "../../../core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "../../../core/domain/notification/model/NotificationLevel";

interface Error {
  error: string;
  description: string;
  cause: string;
}

export type PrintProductVariantLabelsData = {
  readonly reference: string;
  readonly quantity: string;
  readonly printerLine: number;
};

type PrintProductVariantLabelsFunction = {
  readonly printLabels: (data: PrintProductVariantLabelsData, onSuccess: () => void) => void;
  readonly printProductVariantsLabelState: AsyncActionState;
};

const usePrintProductVariantLabels = (): PrintProductVariantLabelsFunction => {
  const dispatch = useDispatch();
  const [printProductVariantsLabelState, setPrintProductVariantLabelsState] = useState<AsyncActionState>(
    AsyncActionState.DEFAULT,
  );

  const printLabels = useCallback(
    async (data: PrintProductVariantLabelsData, onSuccess: () => void) => {
      try {
        setPrintProductVariantLabelsState(AsyncActionState.PENDING);

        await dispatch(new PrintProductVariantLabels(data.reference, data.quantity, data.printerLine));

        setPrintProductVariantLabelsState(AsyncActionState.SUCCESS);

        onSuccess();
      } catch (error) {
        console.log(error);
        const message = (error as Error)?.description || "Ha habido un problema al imprimir las etiquetas";

        dispatch(
          new CreateNotification({
            level: NotificationLevel.ERROR,
            content: message,
          }),
        );

        setPrintProductVariantLabelsState(AsyncActionState.ERROR);
      }
    },
    [dispatch],
  );

  return { printLabels, printProductVariantsLabelState };
};

export default usePrintProductVariantLabels;
