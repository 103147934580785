import { AggregateRoot } from "@lookiero/messaging.js";
import OrdersCreated from "./OrdersCreated";

interface ProductVariant {
  readonly id: string;
  readonly quantity: number;
  readonly customerOrder: string;
}

export interface Orders {
  readonly productVariants: ProductVariant[];
  readonly userId: string;
}

class OrdersModel extends AggregateRoot {
  public orders: Orders;

  constructor(orders: Orders) {
    super();
    this.orders = orders;
  }

  public static createMultipleOrders(orders: Orders): OrdersModel {
    const instance = new OrdersModel(orders);
    instance.record(new OrdersCreated(instance.orders));

    return instance;
  }
}

export default OrdersModel;
