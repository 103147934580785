import { Modal, Text, Field, Input, Button, ButtonVariant } from "@lookiero/react-ui-kit";
import { FormikProps, withFormik } from "formik";
import { useCallback, useMemo, useRef } from "react";
import validationSchema from "./validationSchema";
import mapPropsToValues from "./mapPropsToValues";

import { ReactIntl } from "@lookiero/i18n.js";
import { PRINT_LABELS_I18N_PREFIX, PrintLabelsI18n } from "../../../../../shared/i18n/printLabels";

import "./print-labels-modal.css";
interface PrintLabelsModalProps {
  readonly isVisible: boolean;
  readonly onClose: () => void;
  readonly onPrint: (productVariant: string, quantity: string) => void;
}

export type PrintLabelsModalFormikProps = {
  readonly quantity?: string;
  readonly reference?: string;
};

const formikEnhancer = withFormik<PrintLabelsModalProps & PrintLabelsModalFormikProps, PrintLabelsModalFormikProps>({
  mapPropsToValues,
  validationSchema,
  handleSubmit: () => void 0,
});

const PrintLabelsModal: React.FC<PrintLabelsModalProps & FormikProps<PrintLabelsModalFormikProps>> = ({
  isVisible,
  values,
  onClose,
  onPrint,
  setFieldValue,
  setFieldTouched,
}) => {
  const modalRef = useRef(null);

  const { quantity, reference } = values;

  const Message = {
    title: ReactIntl.useI18nMessage({
      prefix: PRINT_LABELS_I18N_PREFIX,
      id: PrintLabelsI18n.TITLE,
    }),
    text: ReactIntl.useI18nMessage({
      prefix: PRINT_LABELS_I18N_PREFIX,
      id: PrintLabelsI18n.TEXT,
    }),
    fields: {
      reference: ReactIntl.useI18nMessage({
        prefix: PRINT_LABELS_I18N_PREFIX,
        id: PrintLabelsI18n.FIELD_REFERENCE,
      }),
    },
    buttons: {
      cancel: ReactIntl.useI18nMessage({
        prefix: PRINT_LABELS_I18N_PREFIX,
        id: PrintLabelsI18n.BUTTON_CANCEL,
      }),
      print: ReactIntl.useI18nMessage({
        prefix: PRINT_LABELS_I18N_PREFIX,
        id: PrintLabelsI18n.BUTTON_PRINT,
      }),
    },
  };

  const handleFieldChanged = useCallback(
    (fieldName: string) =>
      (value: unknown): void => {
        setFieldTouched(fieldName);
        setFieldValue(fieldName, value);
      },
    [setFieldTouched, setFieldValue],
  );

  const handleQuantityChange = useMemo(() => handleFieldChanged("quantity"), [handleFieldChanged]);
  const handleReferenceChange = useMemo(() => handleFieldChanged("reference"), [handleFieldChanged]);

  return (
    <Modal viewRef={modalRef} visible={isVisible} onCancel={onClose} title={Message.title}>
      <div className="print-labels-modal-content">
        <Text className="print-labels-modal-title">{Message.text + ":"}</Text>
        <div className="print-labels-modal-fields">
          <Field className="print-labels-modal-field">
            <Input value={quantity || "1"} type="number" onChange={handleQuantityChange} />
          </Field>
          <Field className="print-labels-modal-product-variant-field">
            <Input
              value={reference || ""}
              placeholder={Message.fields.reference}
              onChange={handleReferenceChange}
              // error={triedSubmitting || touched.attributes?.height ? errors.attributes?.height : undefined}
            />
          </Field>
        </div>
      </div>
      <div className="print-labels-modal-footer">
        <Button variant={ButtonVariant.BASIC} onClick={onClose}>
          <Text>{Message.buttons.cancel}</Text>
        </Button>
        <Button
          disabled={!reference || !quantity}
          variant={ButtonVariant.PRIMARY}
          onClick={() => {
            if (!reference || !quantity) {
              return;
            }
            onPrint(reference, quantity);
            onClose();
          }}
        >
          <Text>{Message.buttons.print}</Text>
        </Button>
      </div>
    </Modal>
  );
};

export default formikEnhancer(PrintLabelsModal);
