import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import OrdersRepository from "@src/core/domain/orders/model/OrdersRepository";
import OrdersModel from "../../../../domain/orders/model/OrdersModel";

class HttpOrdersRepository implements OrdersRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async createMultipleOrders(model: OrdersModel): Promise<void> {
    try {
      const response = await this.httpClient.post("/create-multiple-orders", {
        product_variants: model.orders.productVariants,
        user_id: model.orders.userId,
      });
      if (!response.ok) {
        const errors = await response.json();
        throw Object.assign({
          message: "Crear pedidos de unsold en el servicio.",
          data: errors,
        });
      }

      this.eventBus.publish(model.recordedEvents());
    } catch (error) {
      throw error;
    }
  }
}

export default HttpOrdersRepository;
