enum EmployeeRoles {
  RECEPTION_ROLE = "OPS_LOGISTICS_RECEPTION",
  CATALOG_ORDERS_ROLE = "OPS_LOGISTICS_CATALOG_ORDERS",
  OPS_LOGISTICS_CATALOG_CATEGORIES = "OPS_LOGISTICS_CATALOG_CATEGORIES",
  OPS_LOGISTICS_PRICE_CHANGE_MANAGEMENT = "OPS_LOGISTICS_PRICE_CHANGE_MANAGEMENT",
  OPS_PRINT_LABELS = "OPS_PRINT_LABELS",
  OPS_UNSOLD_RESTOCK = "OPS_UNSOLD_RESTOCK",
}

export default EmployeeRoles;
