import { CommandHandler } from "@lookiero/messaging.js";
import CreateMultipleOrdersModel from "./CreateMultipleOrdersModel";
import Orders from "../../model/OrdersModel";
import OrdersRepository from "../../model/OrdersRepository";

class CreateMultipleOrdersHandler implements CommandHandler<CreateMultipleOrdersModel> {
  private repository: OrdersRepository;

  public constructor(repository: OrdersRepository) {
    this.repository = repository;
  }

  public async execute({ orders }: CreateMultipleOrdersModel): Promise<void> {
    const createMultipleOrdersInstance = Orders.createMultipleOrders(orders);

    return this.repository.createMultipleOrders(createMultipleOrdersInstance);
  }
}

export default CreateMultipleOrdersHandler;
