import { AggregateRoot } from "@lookiero/messaging.js";
import LabelPrinted from "./LabelPrinted";

class Label extends AggregateRoot {
  public reference: string;
  public quantity: string;
  public printerLine: number;

  public constructor(reference: string, quantity: string, printerLine: number) {
    super();
    this.reference = reference;
    this.quantity = quantity;
    this.printerLine = printerLine;
  }

  public static printByReference(reference: string, quantity: string, printerLine: number): Label {
    const instance = new Label(reference, quantity, printerLine);
    instance.record(new LabelPrinted(reference, quantity, printerLine));

    return instance;
  }
}

export default Label;
