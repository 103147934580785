import { useMemo } from "react";
import { RouteItem } from "@lookiero/react-ui-kit";
import App from "../model/App";
import Environment from "../model/Environment";
import { useGetCurrentEmployee } from "../../../../views/_behaviors/employeeProvider";

//  TODO get these from config.json
const routes: Record<App, Record<Environment, string>> = {
  [App.CATALOG]: {
    [Environment.DEV]: "https://catalog-proxy.dev.envs.lookiero.tech",
    [Environment.PROD]: "https://catalog.lookiero.com",
  },
  [App.BUYING]: {
    [Environment.DEV]: "https://buying-proxy.dev.envs.lookiero.tech",
    [Environment.PROD]: "https://buying.lookiero.com",
  },
  [App.STOCK_DASHBOARD]: {
    [Environment.DEV]: "http://stock.prod.aws.lookiero.com/dashboard_stock/analisis_grupos.html?tab=home",
    [Environment.PROD]: "http://stock.prod.aws.lookiero.com/dashboard_stock/analisis_grupos.html?tab=home",
  },
  [App.STOCK_BETA]: {
    [Environment.DEV]: "https://catalog-proxy.dev.envs.lookiero.tech/es/unsold-stock",
    [Environment.PROD]: "https://catalog.lookiero.com/es/unsold-stock",
  },
};

const useRoutesForEnvironment = (): RouteItem[] => {
  const employee = useGetCurrentEmployee();

  const currentEnvironment = useMemo(
    () =>
      Object.values(routes).reduce(
        (environment, route) => {
          if (environment) {
            return environment;
          }

          return Object.keys(route).find((envKey) =>
            window.location.href.includes(route[envKey as Environment]),
          ) as Environment;
        },
        undefined as Environment | undefined,
      ) || Environment.DEV,
    [],
  );

  const menuItems = useMemo(() => {
    const hasUnsoldRestockRole = employee?.hasUnsoldRestockRole() as boolean;

    const items = [
      {
        label: "Catálogo",
        to: routes[App.CATALOG][currentEnvironment],
      },
      {
        label: "Stock",
        to: routes[App.STOCK_DASHBOARD][currentEnvironment],
      },
      {
        label: "Compras",
        to: routes[App.BUYING][currentEnvironment],
      },
    ];

    if (hasUnsoldRestockRole) {
      items.push({
        label: "Stock (BETA)",
        to: routes[App.STOCK_BETA][currentEnvironment],
      });
    }

    return items;
  }, [currentEnvironment, employee]);

  return menuItems;
};

export default useRoutesForEnvironment;
