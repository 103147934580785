import CreateNotificationWhenError from "@src/core/infrastructure/application/CreateNotificationWhenError";
import LogoutWhenUnAuthorizedEmployeeError from "@src/core/infrastructure/application/LogoutWhenUnAuthorizedEmployeeError";
import LogWhenError from "@src/core/infrastructure/application/LogWhenError";
import CreateNotificationWhenAvailabilityEdited from "@src/core/processManager/createNotification/CreateNotificationWhenAvailabilityEdited";
import CreateNotificationWhenFamilyCreated from "@src/core/processManager/createNotification/CreateNotificationWhenFamilyCreated";
import CreateNotificationWhenFamilyFeatureValuesCloned from "@src/core/processManager/createNotification/CreateNotificationWhenFamilyFeatureValuesCloned";
import CreateNotificationWhenFamilyFeatureValuesEdited from "@src/core/processManager/createNotification/CreateNotificationWhenFamilyFeatureValuesEdited";
import CreateNotificationWhenFashionLabsModelCreated from "@src/core/processManager/createNotification/CreateNotificationWhenFashionLabsModelCreated";
import CreateNotificationWhenFashionLabsModelEdited from "@src/core/processManager/createNotification/CreateNotificationWhenFashionLabsModelEdited";
import CreateNotificationWhenFashionLabsProductCreated from "@src/core/processManager/createNotification/CreateNotificationWhenFashionLabsProductCreated";
import CreateNotificationWhenFashionLabsProductEdited from "@src/core/processManager/createNotification/CreateNotificationWhenFashionLabsProductEdited";
import CreateNotificationWhenOrderCreated from "@src/core/processManager/createNotification/CreateNotificationWhenOrderCreated";
import CreateNotificationWhenOrderItemAdded from "@src/core/processManager/createNotification/CreateNotificationWhenOrderItemAdded";
import CreateNotificationWhenOrderItemRemoved from "@src/core/processManager/createNotification/CreateNotificationWhenOrderItemRemoved";
import CreateNotificationWhenOrderPlaced from "@src/core/processManager/createNotification/CreateNotificationWhenOrderPlaced";
import CreateEmployeeWhenAuthenticated from "@src/core/processManager/employee/CreateEmployeeWhenAuthenticated";
import CreateNotificationWhenLabelPrinted from "../../processManager/createNotification/CreateNotificationWhenLabelPrinted";

const processManagerRegisterList = [
  { identifier: "LogWhenError", dependencies: ["Logger"], classInjection: LogWhenError },
  { identifier: "CreateNotificationWhenError", dependencies: [], classInjection: CreateNotificationWhenError },
  {
    identifier: "LogoutWhenUnAuthorizedEmployeeError",
    dependencies: [],
    classInjection: LogoutWhenUnAuthorizedEmployeeError,
  },
  {
    identifier: "CreateNotificationWhenAvailabilityEdited",
    dependencies: [],
    classInjection: CreateNotificationWhenAvailabilityEdited,
  },
  {
    identifier: "CreateNotificationWhenFashionLabsProductCreated",
    dependencies: [],
    classInjection: CreateNotificationWhenFashionLabsProductCreated,
  },
  {
    identifier: "CreateNotificationWhenFashionLabsProductEdited",
    dependencies: ["FashionLabsProductView"],
    classInjection: CreateNotificationWhenFashionLabsProductEdited,
  },
  {
    identifier: "CreateNotificationWhenFashionLabsModelCreated",
    dependencies: [],
    classInjection: CreateNotificationWhenFashionLabsModelCreated,
  },
  {
    identifier: "CreateNotificationWhenFashionLabsModelEdited",
    dependencies: [],
    classInjection: CreateNotificationWhenFashionLabsModelEdited,
  },
  { identifier: "CreateEmployeeWhenAuthenticated", dependencies: [], classInjection: CreateEmployeeWhenAuthenticated },
  {
    identifier: "CreateNotificationWhenOrderItemRemoved",
    dependencies: [],
    classInjection: CreateNotificationWhenOrderItemRemoved,
  },
  {
    identifier: "CreateNotificationWhenOrderItemAdded",
    dependencies: [],
    classInjection: CreateNotificationWhenOrderItemAdded,
  },
  {
    identifier: "CreateNotificationWhenOrderPlaced",
    dependencies: [],
    classInjection: CreateNotificationWhenOrderPlaced,
  },
  {
    identifier: "CreateNotificationWhenOrderCreated",
    dependencies: [],
    classInjection: CreateNotificationWhenOrderCreated,
  },
  {
    identifier: "CreateNotificationWhenFamilyCreated",
    dependencies: [],
    classInjection: CreateNotificationWhenFamilyCreated,
  },
  {
    identifier: "CreateNotificationWhenFamilyFeatureValuesCloned",
    dependencies: [],
    classInjection: CreateNotificationWhenFamilyFeatureValuesCloned,
  },
  {
    identifier: "CreateNotificationWhenFamilyFeatureValuesEdited",
    dependencies: [],
    classInjection: CreateNotificationWhenFamilyFeatureValuesEdited,
  },
  {
    identifier: "CreateNotificationWhenLabelPrinted",
    dependencies: [],
    classInjection: CreateNotificationWhenLabelPrinted,
  },
];

export { processManagerRegisterList };
