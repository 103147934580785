import HttpClient from "@src/shared/delivery/application/HttpClient";
import packageInfo from "../../../../../../package.json";
import Environment from "../../../../projection/environment/model/Environment";
import EnvironmentFetcher from "../../../../projection/environment/model/EnvironmentFetcher";

type EnvironmentResponseDto = {
  catalog_back_url: string;
  cdn_url: string;
  authApi: string;
  sentry_public_key: string;
  sentry_project: string;
  storage: string;
  internationalization: {
    default_locale: string;
    endpoint: string;
    api_key: string;
    storage_namespace: string;
  };
  featureToggle: {
    jwtLoginEnabled: boolean;
  };
  hotjar: {
    userId: number;
    version: number;
  };
  printer_product_variant: number;
};

class HttpEnvironmentFetcher implements EnvironmentFetcher {
  private client: HttpClient;
  private environment!: Environment;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public async fetch(): Promise<Environment> {
    if (!this.environment) {
      const response = await this.client.get(`/config.json?${packageInfo.version}`);
      const dto: EnvironmentResponseDto = await response.json();
      this.environment = {
        catalogBackUrl: dto.catalog_back_url,
        cdnUrl: dto.cdn_url,
        authApi: dto.authApi,
        sentryPublicKey: dto.sentry_public_key,
        sentryProject: dto.sentry_project,
        storage: dto.storage,
        internationalization: {
          defaultLocale: dto.internationalization.default_locale,
          endpoint: dto.internationalization.endpoint,
          apiKey: dto.internationalization.api_key,
          storageNamespace: dto.internationalization.storage_namespace,
        },
        featureToggle: {
          jwtLoginEnabled: dto.featureToggle.jwtLoginEnabled,
        },
        hotjar: {
          userId: dto.hotjar.userId,
          version: dto.hotjar.version,
        },
        printerProductVariant: dto.printer_product_variant,
      };
    }

    return this.environment;
  }
}

export default HttpEnvironmentFetcher;
