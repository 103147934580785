import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import LabelRepository from "../../../../domain/label/model/LabelRepository";
import Label from "../../../../domain/label/model/Label";

class HttpLabelRepository implements LabelRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async printByReference(label: Label): Promise<void> {
    try {
      const body = {
        reference: label.reference,
        copies: label.quantity,
        printer_line: label.printerLine,
      };
      const response = await this.httpClient.post("/print-product-variant-label-by-reference", body);

      if (!response.ok) {
        const result = await response.json();
        throw result;
      }

      this.eventBus.publish(label.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default HttpLabelRepository;
