import { Command } from "@lookiero/messaging.js";

class PrintProductVariantLabels extends Command {
  public readonly reference: string;
  public readonly quantity: string;
  public readonly printerLine: number;

  public constructor(reference: string, quantity: string, printerLine: number) {
    super();
    this.reference = reference;
    this.quantity = quantity;
    this.printerLine = printerLine;
  }

  public messageName(): string {
    return "PrintProductVariantLabels";
  }
}

export default PrintProductVariantLabels;
