import { DomainEvent } from "@lookiero/messaging.js";

class LabelPrinted extends DomainEvent {
  public reference: string;
  public quantity: string;
  public printerLine: number;

  public constructor(reference: string, quantity: string, printerLine: number) {
    super();
    this.reference = reference;
    this.quantity = quantity;
    this.printerLine = printerLine;
  }

  public messageName(): string {
    return "LabelPrinted";
  }
}

export default LabelPrinted;
