import React, { FC, useEffect, useMemo } from "react";
import { Outlet, generatePath, useLocation, useNavigate, useRoutes } from "react-router-dom";
import Routes from "./routes";
import AuthenticationMiddleware from "./AuthenticationMiddleware";
import Hub from "../component-library/layouts/hub/Hub";
import Header from "../component-library/organisms/Header/Header";
import FashionLabsHeader from "../component-library/organisms/Header/FashionLabsHeader/FashionLabsHeader";
import { StaticResourcesProvider } from "../views/_behaviors/useQueryStaticResources";
import useIsEmployeeStillLoggedIn from "../views/_behaviors/useIsEmployeeStillLoggedIn";
import { useGetCurrentEmployee } from "../views/_behaviors/employeeProvider";
import LogoutButton from "@src/ui/component-library/atoms/LogoutButton/LogoutButton";
import PriceManagement from "../views/priceManagement/PriceManagement";
import PriceManagementOutcome from "../views/priceManagementOutcome/PriceManagementOutcome";
import usePrintProductVariantLabelsByReference from "../views/_behaviors/usePrintProductVariantLabelsByReference";
import useEnvironment from "../views/_behaviors/useEnvironment";

const Login = React.lazy(() => import("../views/login/Login"));
const Catalog = React.lazy(() => import("../views/catalog/CatalogContainer"));
const Product = React.lazy(() => import("../views/product/Product"));
const Availability = React.lazy(() => import("../views/catalog/ProductAvailability"));
const Visibility = React.lazy(() => import("../views/catalog/ProductVisibility"));
const FashionLabsProductsOverview = React.lazy(
  () => import("../views/fashionLabsProductsOverview/FashionLabsProductsOverview"),
);
const FashionLabsProduct = React.lazy(() => import("../views/fashionLabsProduct/FashionLabsProduct"));
const FashionLabsModelsOverview = React.lazy(() => import("../views/fashionLabsModels/FashionLabsModelsOverview"));
const FashionLabsModelsForm = React.lazy(() => import("../views/fashionLabsModels/FashionLabsModelsForm"));
const FashionLabsNewModelForm = React.lazy(() => import("../views/fashionLabsModels/FashionLabsNewModelForm"));
const Order = React.lazy(() => import("../views/order/Order"));
const FamiliesOverview = React.lazy(() => import("../views/families/FamiliesOverview"));
const FamiliesNewForm = React.lazy(() => import("../views/families/FamiliesNewForm"));
const Family = React.lazy(() => import("../views/family/Family"));
const UnsoldStock = React.lazy(() => import("../views/unsoldStock/UnsoldStock"));
const UnsoldStockOutcome = React.lazy(() => import("../views/unsoldStockOutcome/UnsoldStockOutcome"));

type RoutingProps = {
  readonly locale: string;
};

const Routing: FC<RoutingProps> = ({ locale }) => {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();
  const { printerProductVariant } = useEnvironment();

  const isEmployeeStillLoggedIn = useIsEmployeeStillLoggedIn();
  const isLoginView = useMemo(() => currentPath === generatePath(Routes.LOGIN, { locale }), [currentPath, locale]);

  const employee = useGetCurrentEmployee();

  const { printLabels: printProductVariantLabels } = usePrintProductVariantLabelsByReference();

  const hasCatalogOrderRole = employee?.hasCatalogOrdersRole() as boolean;
  const hasCatalogCategoriesRole = employee?.hasCatalogCategoriesRole() as boolean;
  const hasCatalogOrderPathIfAccess = useMemo(
    () =>
      hasCatalogOrderRole
        ? [
            {
              path: Routes.ORDER,
              element: <Order />,
            },
          ]
        : [],
    [hasCatalogOrderRole],
  );

  const hasCatalogCategoriesPathIfAccess = useMemo(
    () =>
      hasCatalogCategoriesRole
        ? [
            {
              path: Routes.FAMILIES_OVERVIEW,
              element: <FamiliesOverview />,
              children: [
                {
                  path: Routes.FAMILY_NEW,
                  element: <FamiliesNewForm />,
                },
              ],
            },
            {
              path: Routes.FAMILY,
              element: <Family locale={locale} />,
            },
            {
              path: Routes.FAMILY_FEATURE,
              element: <Family locale={locale} />,
            },
          ]
        : [],
    [hasCatalogCategoriesRole, locale],
  );

  useEffect(() => {
    if (currentPath === generatePath(Routes.ROOT)) {
      navigate(generatePath(`/${Routes.PRODUCTS}`, { locale }), { replace: true });
    }
    if (isEmployeeStillLoggedIn && isLoginView) {
      navigate(generatePath(`/${Routes.PRODUCTS}`, { locale }), { replace: true });
    }
  }, [currentPath, navigate, locale, isEmployeeStillLoggedIn, isLoginView]);

  const routes = useMemo(
    () => [
      {
        path: Routes.LOGIN,
        element: <Login />,
      },
      {
        path: "",
        element: (
          <AuthenticationMiddleware locale={locale}>
            <StaticResourcesProvider>
              <Hub>
                <LogoutButton />
              </Hub>
              <Header
                locale={locale}
                onPrintProductVariantLabels={(reference: string, quantity: string, onSuccess: () => void) =>
                  printProductVariantLabels({ reference, quantity, printerLine: printerProductVariant }, onSuccess)
                }
              />
              <FashionLabsHeader locale={locale} />
              {/* <CategoriesHeader locale={locale} /> */}
              <Outlet />
            </StaticResourcesProvider>
          </AuthenticationMiddleware>
        ),
        children: [
          {
            path: Routes.PRODUCTS,
            element: <Catalog />,
            children: [
              {
                path: Routes.AVAILABILITY,
                element: <Availability />,
              },
              {
                path: Routes.VISIBILITY_LITE,
                element: <Visibility />,
              },
            ],
          },
          {
            path: Routes.PRODUCT,
            element: <Product />,
          },
          {
            path: Routes.FL_PRODUCTS_OVERVIEW,
            element: <FashionLabsProductsOverview />,
          },
          {
            path: Routes.FL_PRODUCT,
            element: <FashionLabsProduct />,
          },
          {
            path: Routes.FL_MODELS_OVERVIEW,
            element: <FashionLabsModelsOverview />,
            children: [
              {
                path: Routes.FL_MODEL_NEW,
                element: <FashionLabsNewModelForm />,
              },
              {
                path: Routes.FL_MODEL,
                element: <FashionLabsModelsForm />,
              },
            ],
          },
          {
            path: Routes.PRICE_MANAGEMENT,
            element: <PriceManagement />,
          },
          {
            path: Routes.PRICE_MANAGEMENT_OUTCOME,
            element: <PriceManagementOutcome />,
          },
          {
            path: Routes.UNSOLD_STOCK,
            element: <UnsoldStock />,
          },
          {
            path: Routes.UNSOLD_STOCK_OUTCOME,
            element: <UnsoldStockOutcome />,
          },
          ...hasCatalogCategoriesPathIfAccess,
          ...hasCatalogOrderPathIfAccess,
        ],
      },
    ],
    [
      locale,
      hasCatalogOrderPathIfAccess,
      hasCatalogCategoriesPathIfAccess,
      printerProductVariant,
      printProductVariantLabels,
    ],
  );
  return useRoutes(routes);
};

export default Routing;
