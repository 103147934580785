import { AggregateRoot } from "@lookiero/messaging.js";
import MediaPerspective from "../../media/model/MediaPerspective";
import MediaPerspectiveToUpload from "../../media/model/MediaPersPectiveToUpload";
import FashionLabsProductCreated from "./FashionLabsProductCreated";
import FashionLabsProductEdited from "./FashionLabsProductEdited";

export type FashionLabsProductFeature = {
  readonly id: string;
  readonly values: {
    readonly id: string; // feature_value_id
    readonly qualifier?: {
      readonly id: string; // feature_id
      readonly values: { id: string }[]; // feature_value_id
    };
  }[];
};

export type FashionLabsProductModelInfo = {
  readonly modelId: string;
  readonly sizeId: string;
  readonly modelFitting: string;
};

class FashionLabsProduct extends AggregateRoot {
  public productId: string;
  public title: string;
  public note: string;
  public familyId: string;
  public brandId: string;
  public group: string;
  public channels: string[];
  public features: FashionLabsProductFeature[];
  public productModel: FashionLabsProductModelInfo;
  public segment: string;
  public media: {
    readonly id: string;
  }[];

  public constructor(
    productId: string,
    title: string,
    note: string,
    familyId: string,
    brandId: string,
    group: string,
    channels: string[],
    features: FashionLabsProductFeature[],
    productModel: FashionLabsProductModelInfo,
    segment: string,
    media: { id: string }[],
  ) {
    super();
    this.productId = productId;
    this.title = title;
    this.note = note;
    this.familyId = familyId;
    this.brandId = brandId;
    this.group = group;
    this.channels = channels;
    this.features = features;
    this.productModel = productModel;
    this.segment = segment;
    this.media = media;
  }

  public static createProduct(
    productId: string,
    title: string,
    note: string,
    familyId: string,
    brandId: string,
    group: string,
    channels: string[],
    features: FashionLabsProductFeature[],
    productModel: FashionLabsProductModelInfo,
    segment: string,
    media: { id: string }[],
  ): FashionLabsProduct {
    const instance = new FashionLabsProduct(
      productId,
      title,
      note,
      familyId,
      brandId,
      group,
      channels,
      features,
      productModel,
      segment,
      media,
    );

    instance.record(new FashionLabsProductCreated(group));

    return instance;
  }

  public editProduct(
    title: string,
    note: string,
    features: FashionLabsProductFeature[],
    productModel: FashionLabsProductModelInfo,
    media: { id: string }[],
  ): void {
    this.title = title;
    this.note = note;
    this.features = features;
    this.productModel = productModel;
    this.media = media;

    this.record(new FashionLabsProductEdited(this.group));
  }

  // TODO: Check if these static methods belong to this domain. Smell when it´s not used any from the instance
  public static isValidGroup(group: string): boolean {
    return Boolean(group?.match(/^[a-z]\d+$/i));
  }

  public static getMediaPerspectiveFromFilename(
    filename: string,
    productGroup: string,
    colorsCode: string[],
  ): MediaPerspectiveToUpload {
    const perspectiveMatchers: Record<MediaPerspectiveToUpload, RegExp> = {
      [MediaPerspective.VIDEO]: new RegExp(`^${productGroup}.mp4$`, "i"), //                  A100.mp4
      [MediaPerspective.COLLAGE]: new RegExp(`^${productGroup}+\\_collage+\\.jpe?g$`, "i"), //  A100_collage.jpg
      [MediaPerspective.MAIN]: new RegExp(`^${productGroup}+c\\d+\\.jpe?g$`, "i"), //        A100C22.jpg
      [MediaPerspective.MAIN_TRANSPARENT]: new RegExp(`^${productGroup}+c\\d+\\.png$`, "i"), //          A100C22.png
      [MediaPerspective.FLAT_BACK]: new RegExp(`^${productGroup}+c\\d+_0\\.jpe?g$`, "i"), //      A100C22_0.jpg
      [MediaPerspective.FRONT]: new RegExp(`^${productGroup}+c\\d+_1\\.jpe?g$`, "i"), //      A100C22_1.jpg
      [MediaPerspective.SIDE]: new RegExp(`^${productGroup}+c\\d+_2\\.jpe?g$`, "i"), //      A100C22_2.jpg
      [MediaPerspective.FRONT_FAR]: new RegExp(`^${productGroup}+c\\d+_3\\.jpe?g$`, "i"), //      A100C22_3.jpg
      [MediaPerspective.BACK]: new RegExp(`^${productGroup}+c\\d+_4\\.jpe?g$`, "i"), //      A100C22_4.jpg
      [MediaPerspective.DETAIL]: new RegExp(`^${productGroup}+c\\d+_5\\.jpe?g$`, "i"), //      A100C22_5.jpg
      [MediaPerspective.LABEL]: new RegExp(`^${productGroup}+c\\d+_6\\.jpe?g$`, "i"), //      A100C22_6.jpg
      [MediaPerspective.BOTTOM_UP]: new RegExp(`^${productGroup}+c\\d+_7\\.jpe?g$`, "i"), //      A100C22_7.jpg
      [MediaPerspective.BACK_FAR]: new RegExp(`^${productGroup}+c\\d+_8\\.jpe?g$`, "i"), //      A100C22_8.jpg
      [MediaPerspective.FAST_TRACK]: new RegExp(`^${productGroup}+c\\d+_9\\.jpe?g$`, "i"), //      A100C22_9.jpg
      [MediaPerspective.FITTING_FRONT]: new RegExp(`^${productGroup}+c\\d+_A\\.jpe?g$`, "i"), //       A100C22_A.jpg
      [MediaPerspective.FITTING_BACK]: new RegExp(`^${productGroup}+c\\d+_B\\.jpe?g$`, "i"), //      A100C22_B.jpg
      [MediaPerspective.FITTING_TOTAL]: new RegExp(`^${productGroup}+c\\d+_C\\.jpe?g$`, "i"), //      A100C22_C.jpg
      [MediaPerspective.FITTING_DETAIL_1]: new RegExp(`^${productGroup}+c\\d+_D\\.jpe?g$`, "i"), //      A100C22_D.jpg
    };

    const [perspective] =
      Object.entries(perspectiveMatchers).find(([, regex]) => (regex as RegExp).test(filename)) || [];

    const isColorPerspective = !["COLLAGE", "VIDEO"].includes(perspective || "");

    const hasPublishableColor = isColorPerspective
      ? colorsCode.some((colorCode) => filename.includes(colorCode))
      : true;

    if (!perspective || !hasPublishableColor) {
      throw new Error("Invalid filename");
    }

    return perspective as MediaPerspectiveToUpload;
  }

  public static getColorCodeFromFilename(filename: string): string | null {
    const [, color] = filename.match(/^[a-z]\d+(c\d+)(?:_[0-9-A-D])?\.(?:jpe?g|png)$/i) || [];
    return color || null;
  }

  public static isMediaPerspectiveFromProduct(perspective: MediaPerspective): boolean {
    return [MediaPerspective.COLLAGE, MediaPerspective.VIDEO].includes(perspective);
  }

  public static isMediaPerspectiveFromProductVariant(perspective: MediaPerspective): boolean {
    return !FashionLabsProduct.isMediaPerspectiveFromProduct(perspective);
  }

  public static getMediaFileName(group: string, color: string | null, perpective: MediaPerspective): string {
    if (!color) {
      return `${group}_${perpective}`;
    }
    return `${group}${color}_${perpective}`;
  }
}

export default FashionLabsProduct;
